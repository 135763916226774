import * as React from 'react'

import { PersonalisedContentType } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import { GridItem } from '@thg-commerce/gravity-system'

import {
  PersonalisedContentWrapper,
  StyledButton,
  StyledImage,
  StyledSafeHtml,
  Title,
  TextGridItem,
  StyledGrid,
} from './styles'

export const PersonalisedContent = ({
  forwardedRef,
  onClick,
  content,
}: {
  forwardedRef: React.RefObject<HTMLDivElement>
  onClick: () => void
  content?: PersonalisedContentType | null
}) => {
  if (!content) {
    return <div ref={forwardedRef} />
  }

  return (
    <div onClick={onClick} ref={forwardedRef} role="button">
      <PersonalisedContentWrapper href={content.cta}>
        <StyledGrid columns={5} rows={2}>
          <GridItem
            colSpan={2}
            colStart={1}
            rowStart={[2, 2, 1, 1]}
            rowSpan={2}
            style={{ position: 'relative' }}
          >
            <StyledImage
              src={[{ url: content.horizontalImageSrc }]}
              alt={content.title}
              width="auto"
            />
          </GridItem>
          <GridItem colSpan={[5, 5, 3, 3]} colStart={[1, 1, 3, 3]} rowStart={1}>
            <Title>{content.title}</Title>
          </GridItem>
          <TextGridItem colSpan={3} rowStart={2} rowSpan={1}>
            <StyledSafeHtml content={content.copy} />
            <StyledButton
              emphasis="high"
              sizing="regular"
              renderedAs="a"
              href={content.cta}
            >
              {content.ctaText}
            </StyledButton>
          </TextGridItem>
        </StyledGrid>
      </PersonalisedContentWrapper>
    </div>
  )
}
