import dynamic from 'next/dynamic'

import {
  css,
  mq,
  spacing,
  styled,
  Text,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'

export const ChevronRight = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronRight'),
)

export const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`

const overlay = css`
  ${zIndex(ZIndexLevel.Highest)};
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: ${spacing(3)} ${spacing(3)} ${spacing(11)} ${spacing(3)};
  background: ${(props) => props.theme.colors.palette.greys.white};
`

export const MobileFacetsContainer = styled.div<{
  showMobileFacets: boolean
}>`
  ${overlay}

  visibility: hidden;
  top: 100%;
  left: 0;

  ${(props) =>
    props.showMobileFacets &&
    css`
      visibility: visible;
      top: 0;
      transition: top 0.5s ease;
    `}

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: none;
  }
`

export const FacetSectionContent = styled.div<{
  showFacetContent: boolean
}>`
  ${overlay}

  top: 0;
  left: 100%;
  transition: left 0.5s ease, right 0.5s ease;

  ${(props) =>
    props.showFacetContent &&
    css`
      left: 0;
    `};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonStyles = css`
  ${Text('bodyText', 'default')};
  display: flex;
  align-items: center;
  margin-left: -${spacing(0.5)};

  svg {
    margin-right: ${spacing(1)};
  }
`

export const BackButton = styled.button`
  ${ButtonStyles}
`

export const CloseButton = styled.button`
  ${ButtonStyles}
`

export const StyledTitle = styled.h2`
  ${Text('large1', 'alternate')};
  margin-top: ${spacing(5)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-top: ${spacing(8)};
  }

  @media (orientation: landscape) {
    margin-top: ${spacing(3)};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: ${spacing(4)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: ${spacing(6)};
  }
`

export const SelectedFacetsContainer = styled.div`
  margin-top: ${spacing(2)};
`

export const FacetSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing(4)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-top: ${spacing(6)};
  }

  @media (orientation: landscape) {
    margin-top: ${spacing(4)};
  }
`

export const SaveWrapper = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0 ${spacing(3)} ${spacing(5)} ${spacing(3)};
  background: ${(props) => props.theme.colors.palette.greys.white};
`

export const SaveButton = styled(Button)`
  height: 100%;
  width: 100%;
`

export const FacetTitleButton = styled.button`
  ${Text('bodyText', 'default')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.colors.palette.greys.light};

  svg {
    margin-right: ${spacing(1)};
  }

  &:not(:first-child) {
    padding-top: ${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding-bottom: ${spacing(3)};

    &:not(:first-child) {
      padding-top: ${spacing(3)};
    }
  }
`

export const SectionCountWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SectionCount = styled.span<{
  addTitleMargins?: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.colors.palette.greys.white};
  background: ${(props) => props.theme.colors.palette.greys.darker};
  border-radius: 50%;
  font-size: 90%;
  margin-left: ${spacing(2)};

  ${(props) =>
    props.addTitleMargins &&
    css`
      margin-top: ${spacing(5)};

      ${mq(props.theme.breakpointUtils.map, 'sm')} {
        margin-top: ${spacing(8)};
      }

      @media (orientation: landscape) {
        margin-top: ${spacing(3)};
      }
    `}
  }
`
