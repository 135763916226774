import { spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import {
  HorizontalAlignment,
  margin,
  TextEntry,
  TextStyle,
} from '@thg-commerce/gravity-theme'

export const Title = styled.p`
  padding: ${spacing(1)} ${spacing(2)};
`

export const StyledWrapper = styled.div<{
  alignment?: HorizontalAlignment
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.alignment ?? 'left'};
  margin: ${margin({ top: 1, bottom: 1 })};
  gap: 10px;
  flex-flow: wrap;
`

export const StyledListItem = styled.div<{
  hasUrl?: boolean
  textStyle?: {
    entry?: TextEntry
    style?: TextStyle
  }
}>`
  ${(props) =>
    Text(
      props.textStyle?.entry ?? 'xsmall',
      props.textStyle?.style ?? 'alternate',
    )};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  :hover {
    cursor: ${(props) => (props.hasUrl ? 'pointer' : 'initial')};
  }
  a {
    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
  }
  a:hover {
    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
  }
  a:focus {
    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
  }
`

export const StyledText = styled.p<{ size: TextEntry }>`
  ${(props) => Text('xsmall', 'alternate')};
`
