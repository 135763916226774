import { styled, spacing, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'

import { AccessibilityButton } from '../../styles'

export const Title = styled.h2`
  ${Text('medium1', 'alternate')};
`

export const ClearAllButton = styled(Button)`
  margin-top: ${spacing(1)};
`

export const FacetsContainer = styled.div`
  margin-top: ${spacing(8)};
`

export const AccordionContainer = styled.div<{ marginTop?: number }>`
  &:not(:first-child) {
    margin-top: ${(props) => spacing(props.marginTop || 4)};
  }
`

export const StyledAccessibilityButton = styled(AccessibilityButton)`
  margin-top: ${spacing(2)};
`
