import { Image } from '@thg-commerce/gravity-elements/'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'

export const PersonalisedContentWrapper = styled.a`
  display: block;
  position: relative;
  background-color: ${(props) =>
    props.theme.widget.productList.qubitPersonalisedContent.backgroundColor};
  text-decoration: none;
`

export const StyledGrid = styled(Grid)`
  grid-template-rows: unset;
  padding-bottom: ${spacing(1.2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding-bottom: 0;
  }
`

export const StyledImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: calc(100% - ${spacing(2)});

    ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
      height: 100%;
    }
  }
`

export const TextGridItem = styled(GridItem)`
  padding-right: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: 0 ${spacing(5)} ${spacing(3)} ${spacing(1)};
  }
`

export const Title = styled.div`
  ${Text('large2', 'alternate')};
  text-align: center;
  padding: ${spacing(1.5)} ${spacing(5)} 0 ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    text-align: left;
    padding-top: ${spacing(3)};
  }
`

export const StyledSafeHtml = styled(SafeHtml)`
  ${Text('bodyText', 'default')}
  b {
    ${Text('bodyText', 'alternate')}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${Text('large1', 'default')}
    b {
      ${Text('large1', 'alternate')}
    }
  }
`

export const StyledButton = styled(Button)`
  margin-top: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: ${spacing(5)};
  }
`
