import { styled, spacing, Text } from '@thg-commerce/enterprise-theme'
import { Input } from '@thg-commerce/gravity-elements'

export const FacetSelectionPanel = styled.div`
  height: ${(props) =>
    props.theme.widget.productList.horizontalFacets?.brandFacet?.height};
  overflow-y: scroll;
  margin: 0 ${spacing(1)} 0 ${spacing(2)};
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.palette.greys.grey};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.palette.greys.dark};
  }
`

export const FacetSelectionWrapper = styled.ul`
  display: flex;
  flex: 0;
  flex-wrap: wrap;
`

export const AlphabetHeaderList = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  background: ${(props) => props.theme.colors.palette.greys.lighter};
  padding: 0 ${spacing(2)} ${spacing(1)};
  margin-bottom: ${spacing(2)};
  padding-top: ${spacing(1)};
`
export const AlphabetHeaderListItem = styled.li<{
  active?: boolean
  disabled?: boolean
}>`
  text-align: center;
  width: 100%;
  border-bottom: solid 3px transparent;
  &:focus-within {
    outline: solid 2px ${(props) => props.theme.colors.palette.brand.base};
    z-index: 2;
  }
  &:hover {
    background: ${(props) => props.theme.colors.palette.brand.lightest};
  }
  &:active {
    background: ${(props) => props.theme.colors.palette.brand.lighter};
  }

  ${(props) =>
    props.active &&
    ` border-bottom: solid 3px;
      color: ${props.theme.colors.palette.brand.base};
    `};

  ${(props) =>
    props.disabled &&
    `
      &:focus-within {
        outline: none;
      }
      &:hover {
        background: inherit;
      }
      &:active {
        background: inherit;
      }
    `};
`
export const AlphabetHeaderButton = styled.button<{
  active?: boolean
  disabled?: boolean
}>`
  ${Text('bodyText', 'default')}
  text-decoration: none;
  width: 100%;
  &:focus {
    border: none;
    outline: none;
  }

  ${(props) =>
    props.disabled &&
    `color: ${props.theme.colors.palette.greys.light}
  `};

  ${(props) =>
    props.active &&
    `${Text('bodyText', 'alternate')}
    color: ${props.theme.colors.palette.brand.base}
  `};
`

export const FacetBorderListItem = styled.li<{
  hasBorder?: boolean
}>`
  margin: 0;

  ${(props) =>
    props.hasBorder &&
    `border: 1px solid ${props.theme.colors.palette.greys.grey};
  margin: 0 ${spacing(2)} ${spacing(2)} ${spacing(2)}

  &:hover {
    border: 1px solid ${props.theme.colors.palette.greys.darker}
  }`};
  flex-basis: calc(33% - ${spacing(2)});
  margin: 0 ${spacing(2)} ${spacing(2)} 0px;
`

export const FacetCategory = styled.p`
  ${Text('medium1', 'alternate')}
  margin: 0 0 ${spacing(1)};
`

export const HidableElement = styled.div<{ hide: boolean }>`
  ${(props) => props.hide && `display: none`}
`

export const BrandSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${spacing(1)} ${spacing(2)};
`

export const BrandSearch = styled(Input)`
  width: 100%;
  max-width: ${(props) =>
    props.theme.widget.productList.horizontalFacets?.brandFacet?.search
      ?.maxWidth ?? '250px'};
`
