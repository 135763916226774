import React from 'react'

import { useEnterpriseContext } from '@thg-commerce/enterprise-core'
import { currencyMap } from '@thg-commerce/enterprise-pricing'
import { VisuallyHidden } from '@thg-commerce/gravity-elements'

import {
  Container,
  InputContainer,
  InputFields,
  PriceRangeHeader,
  PriceSliderHeader,
  StyledInput,
  StyledPriceSlider,
} from './styles'

const DEBOUNCE_RATE = 1000 // TODO: Determine if this is the best rate to use, need to communicate with horizon.

export interface PriceSliderFacetProps {
  /** Maximum Price Value. */
  maximumPrice: number
  /** Current Minimum Price Value. */
  currentMinimumPrice: number
  /** Current Maximum Price Value. */
  currentMaximumPrice: number
  /** One handle or two? */
  isSingleHandle?: boolean
  i18nText: {
    minimumLabel: string
    maximumLabel: string
    sliderHeader: string
  }
  /** Callback to set the value to the parent to use for query */
  onChange: (values: { minimumPrice: number; maximumPrice: number }) => void
}

export const PriceSliderFacet = (props: PriceSliderFacetProps) => {
  const { currency } = useEnterpriseContext()

  const isValidNumber = (value: number, boundary: number) => {
    return value >= 0 && value <= boundary
  }

  const [selectedMinimumValue, setSelectedMinimumValue] = React.useState(
    isValidNumber(props.currentMinimumPrice, props.maximumPrice)
      ? props.currentMinimumPrice
      : 0,
  )
  const [selectedMaximumValue, setSelectedMaximumValue] = React.useState(
    isValidNumber(props.currentMaximumPrice, props.maximumPrice)
      ? props.currentMaximumPrice
      : props.maximumPrice,
  )

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (
        selectedMinimumValue !== props.currentMinimumPrice ||
        selectedMaximumValue !== props.currentMaximumPrice
      ) {
        props.onChange({
          minimumPrice: selectedMinimumValue,
          maximumPrice: selectedMaximumValue,
        })
      }
    }, DEBOUNCE_RATE)

    return () => clearTimeout(timer)
  }, [selectedMinimumValue, selectedMaximumValue])

  const handleInputChange = (e, inputType: string) => {
    if (!e.currentTarget.value) {
      return
    }
    if (inputType === 'minimumPrice') {
      isValidNumber(e.currentTarget.valueAsNumber, props.maximumPrice)
        ? setSelectedMinimumValue(e.currentTarget.valueAsNumber)
        : setSelectedMinimumValue(0)
    } else if (inputType === 'maximumPrice') {
      isValidNumber(e.currentTarget.valueAsNumber, props.maximumPrice) &&
      e.currentTarget.valueAsNumber > selectedMinimumValue
        ? setSelectedMaximumValue(e.currentTarget.valueAsNumber)
        : setSelectedMaximumValue(props.maximumPrice)
    }
  }

  return (
    <Container data-testid="facet-price-container">
      <PriceSliderHeader>{props.i18nText.sliderHeader}</PriceSliderHeader>
      <PriceRangeHeader>
        {currencyMap[currency].symbol}
        {selectedMinimumValue.toFixed(2)} - {selectedMaximumValue.toFixed(2)}
      </PriceRangeHeader>
      <StyledPriceSlider
        minValue={0}
        maxValue={props.maximumPrice}
        selectedMinValue={
          isValidNumber(selectedMinimumValue, props.maximumPrice)
            ? selectedMinimumValue
            : 0
        }
        selectedMaxValue={
          isValidNumber(selectedMaximumValue, props.maximumPrice)
            ? selectedMaximumValue
            : props.maximumPrice
        }
        isSingleHandle={props.isSingleHandle}
        setSliderMin={setSelectedMinimumValue}
        setSliderMax={setSelectedMaximumValue}
      />
      <InputContainer>
        {!props.isSingleHandle && (
          <InputFields>
            <VisuallyHidden text={props.i18nText.minimumLabel} />
            <StyledInput
              delete={false}
              type="number"
              minValue="0"
              maxValue={selectedMaximumValue.toFixed(2)}
              label={currencyMap[currency].symbol}
              valueOverride={
                isValidNumber(selectedMinimumValue, props.maximumPrice)
                  ? selectedMinimumValue.toFixed(2)
                  : '1'
              }
              onKeyDown={(e) => {
                e.keyCode === 13 && handleInputChange(e, 'minimumPrice')
              }}
              onBlur={(e) => {
                handleInputChange(e, 'minimumPrice')
              }}
            />
          </InputFields>
        )}
        <InputFields>
          <VisuallyHidden text={props.i18nText.maximumLabel} />
          <StyledInput
            delete={false}
            type="number"
            minValue={selectedMinimumValue.toFixed(2)}
            maxValue={props.maximumPrice.toFixed(2)}
            label={currencyMap[currency].symbol}
            valueOverride={
              isValidNumber(selectedMaximumValue, props.maximumPrice)
                ? selectedMaximumValue.toFixed(2)
                : props.maximumPrice.toString(10)
            }
            onKeyDown={(e) => {
              e.keyCode === 13 && handleInputChange(e, 'maximumPrice')
              if (
                e.currentTarget.valueAsNumber &&
                e.currentTarget.valueAsNumber > props.maximumPrice
              ) {
                e.currentTarget.value = props.maximumPrice.toString()
              }
            }}
            onBlur={(e) => {
              handleInputChange(e, 'maximumPrice')
            }}
          />
        </InputFields>
      </InputContainer>
    </Container>
  )
}
