import * as React from 'react'
import { withESI } from '@thg-commerce/enterprise-esi'
import SponsoredAds from '@thg-commerce/enterprise-components/SponsoredAds/SponsoredAds'
import { useHorizonFeature, useSiteConfig } from '@thg-commerce/enterprise-core'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import { SearchData, SponsoredAdsType } from '../SponsoredAds/queryBuilders'

export const ProductList = styled.ul<{
  oneProductPerRow?: boolean
  fourProductsPerRowDesktop?: boolean
  isHorizontalFacetsEnabled?: boolean
  twoProductsPerRowDesktop?: boolean
}>`
  list-style: none;
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.oneProductPerRow ? 1 : 2}
      , minmax(0,1fr))`};

  flex-wrap: wrap;
  justify-content: start;
  margin: 0 -${(props) => spacing(props.theme.widget.productList.gridGap / 2)};

  margin: 0
    ${(props) =>
      props.oneProductPerRow
        ? '0'
        : `-${spacing(props.theme.widget.productList.gridGap / 2)}`};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: 0 -${(props) => spacing(props.theme.widget.productList.gridGap / 2)};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    grid-template-columns: ${(props) =>
      `repeat(${
        props.fourProductsPerRowDesktop || props.isHorizontalFacetsEnabled
          ? 4
          : props.twoProductsPerRowDesktop
          ? 2
          : 3
      }, minmax(0,1fr))`};
  }
`

const SponsoredAdsESI = withESI(SponsoredAds, 'sponsored-ads')

interface ProductListRendererProps {
  path?: string
  productBlocks?: JSX.Element[]
  sponsoredAdsType?: SponsoredAdsType
  searchData?: SearchData
  isHorizontalFacetsEnabled?: boolean
  oneProductPerRow?: boolean
  fourProductsPerRowDesktop?: boolean
  skuWithSponsoredAds?: number
}

export const ProductListRenderer = (props: ProductListRendererProps) => {
  const { enableSponsoredAds } = useSiteConfig()

  const sponsoredAdsEnabled = useHorizonFeature(Feature.SponsoredAds)

  return (
    <ProductList
      data-testid="product-list-page-product-list"
      isHorizontalFacetsEnabled={props.isHorizontalFacetsEnabled}
      oneProductPerRow={props.oneProductPerRow}
      fourProductsPerRowDesktop={props.fourProductsPerRowDesktop}
      twoProductsPerRowDesktop={props.sponsoredAdsType === SponsoredAdsType.PDP}
    >
      {enableSponsoredAds && sponsoredAdsEnabled && props.sponsoredAdsType && (
        <SponsoredAdsESI
          path={props.path}
          sponsoredAdsType={props.sponsoredAdsType}
          searchData={props.searchData}
          productSku={props.skuWithSponsoredAds}
        />
      )}
      {props.productBlocks}
    </ProductList>
  )
}
