import * as React from 'react'
import * as Cookies from 'js-cookie'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { CallbackSetEvent } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'

import { QubitPersonalisedContent } from './QubitPersonalisedContent'

interface QubitPersonalisedContentRendererType {
  emitImpressionEvent?: (params: {
    logger: any
    eventType: CallbackSetEvent
    callbackData?: string
    callbackURL?: string
  }) => void
}

export const QubitPersonalisedContentRenderer = (
  props: QubitPersonalisedContentRendererType,
) => {
  const { qubit } = useSiteConfig()

  if (
    !qubit?.enabled ||
    !qubit?.placements?.personalisedContentId ||
    !Cookies.get('_qubitTracker')
  ) {
    return null
  }

  return (
    <QubitPersonalisedContent
      emitImpressionEvent={props.emitImpressionEvent}
      placementId={qubit.placements.personalisedContentId}
    />
  )
}
