import * as React from 'react'
import loadable from '@loadable/component'

const ChevronLeft = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronLeft'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ChevronRight = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronRight'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import {
  Container,
  ListContainer,
  LeftCarouselButton,
  RightCarouselButton,
} from './styles'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'
import { useTheme } from '@thg-commerce/enterprise-core'

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

enum ScrollDirection {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum CarouselButtonStyle {
  GRADIENT = 'GRADIENT',
  OPACITY = 'OPACITY',
}

export interface HorizontalScrollWrapperProps {
  children: React.ReactNode
  backgroundColour: string
  gap?: number
  margin?: string
  alignment: HorizontalAlignment
  buttonStyle: CarouselButtonStyle
}

export const HorizontalScrollWrapper = (
  props: HorizontalScrollWrapperProps,
) => {
  const navigationContainerRef = React.useRef<HTMLUListElement>(null)
  const listLeftButtonRef = React.useRef<HTMLDivElement>(null)
  const listRightButtonRef = React.useRef<HTMLDivElement>(null)
  const [leftButtonOpacity, setLeftButtonOpacity] = React.useState<number>(0)
  const [rightButtonOpacity, setRightButtonOpacity] = React.useState<number>(
    100,
  )
  const [hideLeftButton, setHideLeftButton] = React.useState<boolean>(true)
  const [hideRightButton, setHideRightButton] = React.useState<boolean>(false)
  const theme = useTheme()

  React.useEffect(() => {
    buttonDisplay()
  }, [])

  const buttonDisplay = () => {
    const navigationContainer: HTMLUListElement | null =
      navigationContainerRef.current

    if (!navigationContainer) {
      return
    }

    const twentyPercentScroll: number =
      (navigationContainer.scrollWidth / 100) * 20
    const eightyPercentScroll: number =
      ((navigationContainer.scrollWidth - navigationContainer.clientWidth) /
        100) *
      80

    if (navigationContainer.scrollWidth === navigationContainer.clientWidth) {
      setHideLeftButton(true)
      setHideRightButton(true)
    }

    if (navigationContainer.scrollLeft <= twentyPercentScroll) {
      const buttonOpacity =
        Math.floor(
          (twentyPercentScroll / 100) * navigationContainer.scrollLeft,
        ) / 100

      buttonOpacity <= 100 && setLeftButtonOpacity(buttonOpacity)
      buttonOpacity === 0
        ? setHideLeftButton(true)
        : hideLeftButton && setHideLeftButton(false)
    }

    if (navigationContainer.scrollLeft >= eightyPercentScroll) {
      const scrollPos = navigationContainer.scrollLeft - eightyPercentScroll
      const maxScroll =
        navigationContainer.scrollWidth -
        navigationContainer.clientWidth -
        eightyPercentScroll
      const buttonOpacity =
        (100 - Math.floor((scrollPos / maxScroll) * 100)) / 100

      buttonOpacity <= 100 && setRightButtonOpacity(buttonOpacity)
      buttonOpacity === 0
        ? setHideRightButton(true)
        : hideRightButton && setHideRightButton(false)
    }
  }

  const scroll = (direction: ScrollDirection) => {
    const navigationContainer: HTMLUListElement | null =
      navigationContainerRef.current

    if (!navigationContainer) {
      return
    }

    let scrollAmount
    const currentScroll = navigationContainer.scrollLeft

    if (window.innerWidth && window.innerWidth < 600) {
      const listLeftButton: HTMLDivElement | null = listLeftButtonRef.current
      const listRightButton: HTMLDivElement | null = listLeftButtonRef.current

      const leftButtonWidth = !hideLeftButton
        ? listLeftButton?.offsetWidth || 0
        : 0
      const rightButtonWidth = !hideRightButton
        ? listRightButton?.offsetWidth || 0
        : 0
      scrollAmount =
        navigationContainer.clientWidth - leftButtonWidth - rightButtonWidth
    } else {
      scrollAmount =
        (navigationContainer.scrollWidth - navigationContainer.clientWidth) / 2
    }

    if (direction === ScrollDirection.LEFT) {
      navigationContainer.scrollLeft = currentScroll - scrollAmount
    } else {
      navigationContainer.scrollLeft = currentScroll + scrollAmount
    }
  }

  return (
    <Container alignment={props.alignment}>
      <LeftCarouselButton
        ref={listLeftButtonRef}
        hide={hideLeftButton}
        buttonStyle={props.buttonStyle}
        backgroundColour={props.backgroundColour}
        displayOpacity={leftButtonOpacity}
        onClick={() => scroll(ScrollDirection.LEFT)}
        aria-hidden
      >
        {theme.patterns.horizontalScrollWrapper.chevronIcon.left.svgPath ? (
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={theme.patterns.horizontalScrollWrapper.chevronIcon.viewBox}
            width={theme.patterns.horizontalScrollWrapper.chevronIcon.width}
            height={theme.patterns.horizontalScrollWrapper.chevronIcon.height}
          >
            <path
              d={
                theme.patterns.horizontalScrollWrapper.chevronIcon.left.svgPath
              }
              fillRule="evenodd"
            />
          </SvgIcon>
        ) : (
          <ChevronLeft width="24" height="24" />
        )}
      </LeftCarouselButton>
      <ListContainer
        gap={props.gap}
        data-testid="scroll-item-list"
        key="list-container"
        ref={navigationContainerRef}
        onScroll={buttonDisplay}
      >
        {props.children}
      </ListContainer>
      <RightCarouselButton
        ref={listRightButtonRef}
        hide={hideRightButton}
        buttonStyle={props.buttonStyle}
        backgroundColour={props.backgroundColour}
        displayOpacity={rightButtonOpacity}
        onClick={() => scroll(ScrollDirection.RIGHT)}
        aria-hidden
      >
        {theme.patterns.horizontalScrollWrapper.chevronIcon.right.svgPath ? (
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={theme.patterns.horizontalScrollWrapper.chevronIcon.viewBox}
            width={theme.patterns.horizontalScrollWrapper.chevronIcon.width}
            height={theme.patterns.horizontalScrollWrapper.chevronIcon.height}
          >
            <path
              d={
                theme.patterns.horizontalScrollWrapper.chevronIcon.right.svgPath
              }
              fillRule="evenodd"
            />
          </SvgIcon>
        ) : (
          <ChevronRight width="24" height="24" />
        )}
      </RightCarouselButton>
    </Container>
  )
}
