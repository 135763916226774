import * as React from 'react'

import { Skeleton } from '@thg-commerce/gravity-elements'
import { styled, spacing } from '@thg-commerce/enterprise-theme'

import {
  HorizontalFacetWrapper,
  FacetContainer,
  ButtonContainer,
} from './styles'

const HorizontalFacetSkeletonItem = styled(Skeleton)`
  width: ${spacing(25)};
  height: ${spacing(6)};
`

const HorizontalFacetButtonSkeleton = styled(Skeleton)`
  width: ${spacing(12)};
  height: ${spacing(4)};
`

interface FacetStyle {
  facet: {
    width: string
    maxWidth: string
  }
  sort: {
    width: string
  }
  container: {
    gap: {
      row: number
      column: number
    }
  }
}

export const HorizontalFacetSkeleton = (props: { facetStyle: FacetStyle }) => {
  return (
    <HorizontalFacetWrapper data-testid="horizontal-facets-skeleton">
      <FacetContainer isOpen={false} gap={props.facetStyle.container.gap}>
        <HorizontalFacetSkeletonItem />
        <HorizontalFacetSkeletonItem />
        <HorizontalFacetSkeletonItem />
        <HorizontalFacetSkeletonItem />
      </FacetContainer>
      <ButtonContainer>
        <HorizontalFacetButtonSkeleton />
        <HorizontalFacetButtonSkeleton />
      </ButtonContainer>
    </HorizontalFacetWrapper>
  )
}
