import * as React from 'react'

import { styled, spacing } from '@thg-commerce/gravity-theme'
import { Skeleton } from '@thg-commerce/gravity-elements'

import { Title, FacetsContainer, AccordionContainer } from './styles'
import {
  StyledListContainer,
  StyledListItem,
} from '../FacetContent/FacetContent'

export interface SkeletonSectionProps {
  itemCount: number
}

export interface VerticalFacetSkeletonProps {
  refineText: string
  sections: SkeletonSectionProps[]
}

const SkeletonSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderSkeletonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing(1)} 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.palette.greys.light};
`

const SkeletonHeading = styled(Skeleton)`
  max-height: ${spacing(2.5)};
  width: 50%;
`

const SkeletonIcon = styled(Skeleton)`
  max-height: ${spacing(3)};
  width: ${spacing(3)};
  display: inline-flex;
  box-sizing: border-box;
`

const CheckboxSkeletonContainer = styled.div`
  box-sizing: border-box;
  padding: ${spacing(1)};
  width: 100%;
  max-height: 48px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  border: 2px solid transparent;
`

const CheckboxLabelSkeletonContainer = styled.div`
  display: flex;
`

const CheckboxSkeleton = styled(Skeleton)`
  width: ${spacing(3)};
  max-height: ${spacing(3)};
  display: inline-flex;
  box-sizing: border-box;
`

const LabelTextSkeleton = styled(Skeleton)`
  flex: 0 0 70%;
  max-height: ${spacing(3)};
  margin: 0px ${spacing(1)} 0px ${spacing(2)};
`

const SkeletonSection = (props: SkeletonSectionProps) => {
  const skeletonListItems = [...Array(props.itemCount)].map((_, index) => (
    <StyledListItem key={`${index}-SkeletonListItem`}>
      <CheckboxSkeletonContainer>
        <CheckboxLabelSkeletonContainer>
          <CheckboxSkeleton />
          <LabelTextSkeleton />
        </CheckboxLabelSkeletonContainer>
      </CheckboxSkeletonContainer>
    </StyledListItem>
  ))

  return (
    <AccordionContainer>
      <SkeletonSectionContainer>
        <HeaderSkeletonContainer>
          <SkeletonHeading />
          <SkeletonIcon />
        </HeaderSkeletonContainer>
        <StyledListContainer>{skeletonListItems}</StyledListContainer>
      </SkeletonSectionContainer>
    </AccordionContainer>
  )
}

export const VerticalFacetSkeleton = (props: VerticalFacetSkeletonProps) => {
  const sectionItems = props.sections.map((section, index) => {
    return (
      <SkeletonSection
        itemCount={section.itemCount}
        key={`${index}-SkeletonSection`}
      />
    )
  })

  return (
    <React.Fragment>
      <Title>{props.refineText}</Title>
      <FacetsContainer data-testid="vertical-facet-skeleton">
        {sectionItems}
      </FacetsContainer>
    </React.Fragment>
  )
}
