import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'

import { FacetDataLayerSchema } from './Facets'

const getFilterObjectMap = (facet: string) => {
  const filterObjectArray: string[] = facet.split(':')
  return {
    filter: `${filterObjectArray[1] || ''}:${filterObjectArray[2] || ''}`,
    filter_category: filterObjectArray[0] || '',
    filter_selection: filterObjectArray[2] || '',
  }
}

export const facetsCallbacks = {
  clearAllClicked: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'remove',
        eventCategory: 'responsiveFacets',
        eventLabel: 'all facets',
        eventLabelValue: undefined,
      },
    })
  },
  addFacetClicked: (facet: string) => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'add',
        eventCategory: 'responsiveFacets',
        eventLabel: 'facet',
        eventLabelValue: facet,
      },
    })
  },
  removeFacetClicked: (facet: string) => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'remove',
        eventCategory: 'responsiveFacets',
        eventLabel: 'facet',
        eventLabelValue: facet,
      },
    })
  },
  openMobileFacetsClicked: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'open',
        eventCategory: 'responsiveFacets',
        eventLabel: 'mobile facets',
        eventLabelValue: undefined,
      },
    })
  },
  closeMobileFacetsClicked: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'close',
        eventCategory: 'responsiveFacets',
        eventLabel: 'mobile facets',
        eventLabelValue: undefined,
      },
    })
  },
  openMobileFacetsContentClicked: (sectionTitle: string) => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'open',
        eventCategory: 'responsiveFacets',
        eventLabel: 'mobile facets section',
        eventLabelValue: sectionTitle,
      },
    })
  },
  closeMobileFacetsContentClicked: (sectionTitle: string) => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'close',
        eventCategory: 'responsiveFacets',
        eventLabel: 'mobile facets section',
        eventLabelValue: sectionTitle,
      },
    })
  },
  errorOnLoad: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'error',
        eventCategory: 'responsiveFacets',
        eventLabel: 'new page load',
        eventLabelValue: undefined,
      },
    })
  },
  addFacetToDataLayer: (
    selectedFacet: string,
    facetSchema: FacetDataLayerSchema,
  ) => {
    const eventData = {
      event: 'custom_event',
      event_name: 'filter_applied',
    }
    const filterObjectMap = getFilterObjectMap(selectedFacet)
    Object.keys(facetSchema).forEach((propertyKey) => {
      eventData[facetSchema[propertyKey].label] = filterObjectMap[propertyKey]
    })

    return window.dataLayer.push(eventData)
  },
}

export const productListCallbacks = {
  emptyProductList: (url: string) => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: url,
        eventCategory: 'Empty List',
        eventLabel: undefined,
        eventLabelValue: undefined,
      },
    })
  },
}
