import * as React from 'react'
import loadable from '@loadable/component'

const { v4: uuid } = require('uuid')

import {
  styled,
  ThemeInterface,
  Text,
  spacing,
} from '@thg-commerce/gravity-theme'
const Tick = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Tick'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import ErrorMessage from '../ErrorMessage'
import { SafeHtml } from '../SafeHtml'

export interface CheckboxProps extends BorderProps {
  checked: boolean
  onChange?: (checked: boolean) => void
  getState?: (state: boolean) => void
  theme?: ThemeInterface
  focused?: boolean
  disabled?: boolean
  error?: string
  label: string
  labelHidden?: boolean
  value?: boolean
  required?: boolean
  bindref?: any
  id?: string
  thinBorder?: boolean
  width?: string
}

interface BorderProps {
  thinFocusBorder?: boolean
  transparentBorder?: boolean
}
interface StyledContainerProps extends BorderProps {
  focused?: boolean
  disabled?: boolean
  width?: string
}

interface StyledCheckboxProps {
  checked?: boolean
  disabled?: boolean
  error?: boolean
  labelHidden?: boolean
  thinBorder?: boolean
}

interface StyledTickProps {
  checked?: boolean
}

export const CheckboxContainer = styled.div<StyledContainerProps>`
  box-sizing: border-box;
  width: ${(props) => props.width || 'fit-content'};
  height: 48px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  pointer-events: ${(props) => (props.disabled ? 'none' : '')};

  border: ${(props) => (props.thinFocusBorder ? '1px' : '2px')} solid
    ${(props) =>
      props.focused && !props.disabled
        ? props.theme.colors.palette.brand.base
        : props.transparentBorder
        ? 'transparent'
        : props.theme.colors.palette.greys.white};

  &:hover {
    background: ${(props) => props.theme.colors.palette.brand.lightest};
  }
  &:active {
    background: ${(props) => props.theme.colors.palette.brand.lighter};
  }
`

const HiddenCheckbox = styled.input`
  position: absolute;
  pointer-events: none;
  overflow: hidden;
  border: 0;
  z-index: -1;
  height: 1px;
  width: 0;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  opacity: 0;
`

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-flex;
  box-sizing: border-box;
  border: ${(props) => (props.thinBorder ? '1px' : '2px')} solid
    ${(props) =>
      !props.error
        ? props.disabled
          ? props.theme.colors.palette.greys.light
          : !props.checked
          ? props.theme.colors.palette.greys.grey
          : props.theme.colors.palette.brand.base
        : props.theme.colors.error.base};
  width: 24px;
  height: 24px;
  background: ${(props) =>
    !props.checked
      ? 'none'
      : props.disabled
      ? props.theme.colors.palette.greys.light
      : props.theme.colors.palette.brand.base};
`

export const StyledTick = styled(Tick)<StyledTickProps>`
  display: block;
  margin: auto;
  stroke: ${(props) =>
    !props.checked ? 'none' : props.theme.colors.palette.greys.white};
`

export const CheckboxLabel = styled.label<StyledCheckboxProps>`
  display: flex;
  position: relative;
  padding: ${spacing(1.5)} ${spacing(1)};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.palette.greys.light
      : props.theme.colors.palette.greys.grey};
  word-break: break-word;
  &:hover {
    cursor: pointer;
  }
`

export const CheckboxText = styled(SafeHtml)<StyledCheckboxProps>`
  ${Text('small', 'default')}
  margin: ${() => `0px ${spacing(1)} 0px ${spacing(2)}`};
  ${(props) => props.labelHidden && 'visibility: hidden;'}
  ${(props) => props.labelHidden && 'position: absolute;'}
  ${(props) => props.labelHidden && 'left: -100000px;'}
`

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-left: ${spacing(1)};
`

/** @component */
export const Checkbox = (props: CheckboxProps) => {
  const [isChecked, setIsChecked] = React.useState(props.checked || false)
  const [focused, setFocused] = React.useState(false)
  const { disabled, label, error, id = uuid() } = props
  const inputEl: React.RefObject<HTMLInputElement> | any =
    props.bindref || React.useRef<HTMLInputElement>(null)

  const elementLabel = (modifier: string) =>
    `${props.label
      ?.toLowerCase()
      .replace(/\:|\?|\.|\!|\"|\'/g, '')
      .split(' ')
      .join('-')}-${modifier}-id-${id}`

  React.useEffect(() => props.getState && props.getState(isChecked), [
    isChecked,
  ])

  React.useEffect(() => {
    props.checked !== isChecked && setIsChecked(props.checked)
  }, [props.checked])

  return (
    <React.Fragment>
      <CheckboxContainer
        disabled={disabled}
        focused={focused}
        data-testid={elementLabel('checkbox-container')}
        width={props.width}
        thinFocusBorder={props.thinFocusBorder}
        transparentBorder={props.transparentBorder}
      >
        <CheckboxLabel disabled={disabled} data-testid="checkbox-label">
          <HiddenCheckbox
            aria-label={props.labelHidden ? props.label : undefined}
            type="checkbox"
            id={id}
            onFocus={() => {
              setFocused(true)
            }}
            onBlur={() => {
              setFocused(false)
            }}
            disabled={disabled}
            checked={isChecked}
            required={props.required}
            onChange={() => {
              props.onChange && props.onChange(!isChecked)
              setIsChecked(!isChecked)
            }}
            value={`${isChecked}`}
            ref={inputEl}
            data-testid={elementLabel('checkbox-hidden')}
          />
          <StyledCheckbox
            aria-hidden={true}
            checked={isChecked}
            disabled={disabled}
            error={!!error}
            data-testid="checkbox-styled"
            thinBorder={props.thinBorder}
          >
            <StyledTick
              checked={isChecked}
              data-testid={elementLabel('checkbox-tick')}
            />
          </StyledCheckbox>
          <CheckboxText
            data-testid={elementLabel('checkbox-text')}
            disabled={props.disabled}
            content={label}
            labelHidden={props.labelHidden}
          />
        </CheckboxLabel>
      </CheckboxContainer>
      {props.error && (
        <StyledErrorMessage
          id={elementLabel(`${label}-error`)}
          error={props.error}
        />
      )}
    </React.Fragment>
  )
}

export default Checkbox
