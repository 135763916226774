import * as React from 'react'
import loadable from '@loadable/component'

import { ZIndexLevel } from '@thg-commerce/gravity-theme'
import { useTheme } from '@thg-commerce/gravity-elements/theme'

import { CustomDropdown, OptionsType } from '../CustomDropdown/CustomDropdown'
import { DropdownOption } from '../FormItem'
import { VisuallyHidden } from '../VisuallyHidden'
import {
  PagniationContainer,
  PaginationContent,
  NavigationButton,
  StyledChevronLeft,
  StyledChevronRight,
  DropdownContainer,
  StyledDropdown,
  StyledLabel,
  Total,
} from './styles'

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export interface PaginationProps {
  totalItems: number
  itemsPerPage: number
  activePage: number
  urlPrefix?: string
  onPageChange: (page: number) => void
  i18nText: {
    previous: string
    next: string
    page: string
    ofTotal: string
    dropdownLabel: string
    currentlyOnText: string
    ariaLabel: string
  }
  // Default alignment is right
  align?: 'left' | 'center' | 'right'
  className?: string
  trailingSlash: boolean
  maxDropdownHeight?: string
  useCustomDropdown?: boolean
  removeBlurFocus?: boolean
  width?: string
  stickyPosition?: boolean
}

const DEFAULT_MAX_DROPDOWN_HEIGHT = '248px'

export const Pagination = (props: PaginationProps) => {
  const theme = useTheme()
  const totalPages = Math.ceil(props.totalItems / props.itemsPerPage)
  const nextPage: number | null =
    props.activePage + 1 <= totalPages ? props.activePage + 1 : null
  const prevPage: number | null =
    props.activePage - 1 > 0 ? props.activePage - 1 : null

  const pageOptions = Array(totalPages)
    .fill(' ')
    .map((_, index) => {
      return (
        <DropdownOption key={index} value={index + 1}>
          {index + 1}
        </DropdownOption>
      )
    })

  const customDropdownPageOptions: OptionsType[] = Array(totalPages)
    .fill(' ')
    .map((_, index) => ({
      key: index + 1,
      value: (index + 1).toString(),
      displayText: (index + 1).toString(),
    }))

  return (
    <PagniationContainer className={props.className} align={props.align}>
      <PaginationContent aria-label={props.i18nText.ariaLabel}>
        <NavigationButton
          as={props.urlPrefix ? `a` : `button`}
          disabled={prevPage === null}
          aria-disabled={prevPage === null}
          role={prevPage === null ? 'button' : undefined}
          emphasis="low"
          onClick={
            !props.urlPrefix
              ? () => props.onPageChange(props.activePage - 1)
              : undefined
          }
          href={
            props.urlPrefix && prevPage !== null
              ? `${props.urlPrefix}${prevPage}${props.trailingSlash ? '/' : ''}`
              : undefined
          }
          data-testid="previous-link"
        >
          {theme.elements?.pagination?.chevronIcon?.left?.svgPath ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.pagination.chevronIcon.viewBox}
              width={theme.elements.pagination.chevronIcon.width}
              height={theme.elements.pagination.chevronIcon.height}
            >
              <path
                d={theme.elements.pagination.chevronIcon.left.svgPath}
                fillRule="evenodd"
              />
            </SvgIcon>
          ) : (
            <StyledChevronLeft />
          )}
          {props.i18nText.previous}
          <VisuallyHidden text={props.i18nText.page} />
        </NavigationButton>
        {props.useCustomDropdown ? (
          <React.Fragment>
            <StyledLabel>
              <VisuallyHidden
                text={`${
                  props.i18nText.dropdownLabel
                }, ${props.i18nText.currentlyOnText
                  .replace('%s', props.activePage.toString())
                  .replace('%s', totalPages.toString())}`}
              />
              <CustomDropdown
                data-testid="select-page-dropdown"
                aria-label={props.i18nText.dropdownLabel}
                onChange={(event) => {
                  props.onPageChange(parseInt(event.value, undefined))
                }}
                options={customDropdownPageOptions}
                selected={props.activePage}
                width={props.width ?? '100%'}
                stickyPosition={props.stickyPosition}
                maxDropdownHeight={
                  props.maxDropdownHeight || DEFAULT_MAX_DROPDOWN_HEIGHT
                }
                removeBlurFocus={props.removeBlurFocus}
                zIndex={ZIndexLevel.Base5}
                iconOverride={{
                  svgPath: theme.elements.pagination.chevronIcon.down.svgPath,
                  viewBox: theme.elements.pagination.chevronIcon.viewBox,
                  width: theme.elements.pagination.chevronIcon.width,
                  height: theme.elements.pagination.chevronIcon.height,
                }}
              />
            </StyledLabel>

            <Total aria-hidden>
              {props.i18nText.ofTotal.replace('%s', totalPages.toString())}
            </Total>
          </React.Fragment>
        ) : (
          <DropdownContainer>
            <label>
              <VisuallyHidden
                text={`${
                  props.i18nText.dropdownLabel
                }, ${props.i18nText.currentlyOnText
                  .replace('%s', props.activePage.toString())
                  .replace('%s', totalPages.toString())}`}
              />
              <StyledDropdown
                label={props.i18nText.dropdownLabel}
                labelHidden
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  props.onPageChange(parseInt(event.target.value, undefined))
                }
                value={props.activePage.toString()}
              >
                {pageOptions}
              </StyledDropdown>
            </label>
            <Total aria-hidden>
              {props.i18nText.ofTotal.replace('%s', totalPages.toString())}
            </Total>
          </DropdownContainer>
        )}
        <NavigationButton
          as={props.urlPrefix ? `a` : `button`}
          disabled={nextPage === null}
          aria-disabled={nextPage === null}
          role={nextPage === null ? 'button' : undefined}
          icon={StyledChevronRight}
          emphasis="low"
          onClick={
            !props.urlPrefix
              ? () => props.onPageChange(props.activePage + 1)
              : undefined
          }
          href={
            props.urlPrefix && nextPage !== null
              ? `${props.urlPrefix}${nextPage}${props.trailingSlash ? '/' : ''}`
              : undefined
          }
          data-testid="next-link"
        >
          {props.i18nText.next} <VisuallyHidden text={props.i18nText.page} />
          {theme.elements?.pagination?.chevronIcon?.right?.svgPath ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.pagination.chevronIcon.viewBox}
              width={theme.elements.pagination.chevronIcon.width}
              height={theme.elements.pagination.chevronIcon.height}
            >
              <path
                d={theme.elements.pagination.chevronIcon.right.svgPath}
                fillRule="evenodd"
              />
            </SvgIcon>
          ) : (
            <StyledChevronRight />
          )}
        </NavigationButton>
      </PaginationContent>
    </PagniationContainer>
  )
}
