import * as React from 'react'
import loadable from '@loadable/component'

import { mq, spacing, styled } from '@thg-commerce/gravity-theme'

import { Button } from '../Button'

const SingleColumnSVG = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/OneColumnOnMobilePLP'),
  { ssr: true, fallback: <div style={{ width: 18, height: 18 }} /> },
)
const SingleRowSVG = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/OneColumnOnMobilePLPSelected'
    ),
  { ssr: true, fallback: <div style={{ width: 18, height: 18 }} /> },
)
const MultiRowSVG = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/TwoColumnOnMobilePLP'),
  { ssr: true, fallback: <div style={{ width: 18, height: 18 }} /> },
)
const SvgTwoItemsPerRowIconSelected = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/TwoColumnOnMobilePLPSelected'
    ),
  { ssr: true, fallback: <div style={{ width: 18, height: 18 }} /> },
)

const GridToggleWrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${spacing(1)} ${spacing(2)} 0 ${spacing(2)};
  gap: ${spacing(2.5)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`

const MobileToggleButton = styled(Button)`
  border: none;
  padding: 0;

  &:hover,
  &:focus {
    border: none;
    padding: 0;
  }
`

export interface ProductListGridSelectorProps {
  productViewColumn: boolean
  setProductViewColumn: React.Dispatch<React.SetStateAction<string>>
  setSingleColumn?: (item: string, value: string) => void
}

const ProductGridType = 'productViewColumn'

export enum GridColumnType {
  SingleColumn = 'singleColumn',
  ProductGrid = 'productGrid',
}

export const ProductListGridSelector = (
  props: ProductListGridSelectorProps,
) => {
  return (
    <GridToggleWrapper>
      <MobileToggleButton
        onClick={() => {
          props.setProductViewColumn(GridColumnType.SingleColumn)
          props.setSingleColumn &&
            props.setSingleColumn(ProductGridType, GridColumnType.SingleColumn)
        }}
        emphasis="low"
      >
        {props.productViewColumn ? <SingleRowSVG /> : <SingleColumnSVG />}
      </MobileToggleButton>
      <MobileToggleButton
        onClick={() => {
          props.setProductViewColumn(GridColumnType.ProductGrid)
          props.setSingleColumn &&
            props.setSingleColumn(ProductGridType, GridColumnType.ProductGrid)
        }}
        emphasis="low"
      >
        {!props.productViewColumn ? (
          <SvgTwoItemsPerRowIconSelected />
        ) : (
          <MultiRowSVG />
        )}
      </MobileToggleButton>
    </GridToggleWrapper>
  )
}
