import * as React from 'react'
import loadable from '@loadable/component'
import ResizeObserver from 'resize-observer-polyfill'

const ChevronDown = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronDown'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
import { SafeHtml, Button } from '@thg-commerce/gravity-elements'
import {
  styled,
  Text,
  spacing,
  css,
  gradient,
  opacity,
} from '@thg-commerce/gravity-theme'
import { useTheme } from '@thg-commerce/enterprise-core'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export interface ReadMoreProps {
  readMoreText: string
  readLessText: string
  content: string
  contentHorizontalAlignment?: string
  horizontalAlignment?: string
  numberOfLinesToShow?: number
}

const READ_MORE_LINE_PREVIEW = 2

const Content = styled(SafeHtml)<{
  horizontalAlignment?: string
}>`
  & > p:first-child {
    margin-top: 0;
  }
  ${(props) =>
    props.horizontalAlignment === HorizontalAlignment.CENTER &&
    `text-align: center;`}
`

const bottomBlur = css`
  flex-wrap: wrap;
  position: relative;
  bottom: ${(props) => props.theme.typography.bodyText.largeDevice.lineHeight};
  margin-bottom: -${(props) => props.theme.typography.bodyText.largeDevice.lineHeight};

  &::before {
    content: '';
    display: block;
    height: ${(props) =>
      props.theme.typography.bodyText.largeDevice.lineHeight};
    width: 100%;
    background: ${(props) =>
      gradient('0deg', [
        { color: opacity(props.theme.colors.palette.greys.white, 1), stop: 0 },
        {
          color: opacity(props.theme.colors.palette.greys.white, 0),
          stop: 100,
        },
      ])};
  }
`

const ButtonIconContainer = styled.div<{
  open: boolean
  horizontalAlignment?: string
}>`
  display: flex;
  align-items: center;
  width: 100%;

  ${(props) =>
    props.horizontalAlignment === HorizontalAlignment.CENTER &&
    `justify-content: center;`}

  ${(props) => !props.open && bottomBlur}

  svg {
    transform: ${(props) => (props.open ? `rotate(180deg)` : `rotate(0deg)`)};
    transition: transform 0.5s ease;
  }
`

const StyledButton = styled(Button)`
  ${Text('bodyText', 'alternate')};

  &:hover,
  &:focus {
    ${Text('bodyText', 'alternate')};
  }
`

const ReadMoreContentContainer = styled.div<{
  open: boolean
  maxHeight: number
  numberOfLinesToShow?: number
}>`
  overflow: hidden;
  height: 100%;
  transition: max-height 0.5s ease;

  max-height: ${(props) =>
    props.open
      ? `${props.maxHeight}px`
      : `calc(${props.theme.typography.bodyText.largeDevice.lineHeight} * ${
          props.numberOfLinesToShow || READ_MORE_LINE_PREVIEW
        })`};
  }
`

const ReadMoreContainer = styled.div<{
  open: boolean
}>`
  display: flex;
  flex-direction: column;
  ${(props) => props.open && `gap: ${spacing(2)};`}
`

export const ReadMore = (props: ReadMoreProps) => {
  const theme = useTheme()

  const containerRef = React.useRef<HTMLDivElement>(null)
  const contentRef = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState(false)
  const [showReadMore, setShowReadMore] = React.useState(true)
  const contentMaxHeight = React.useRef(0)

  if (!props.content) {
    return null
  }

  React.useEffect(() => {
    let resizeObserver: ResizeObserver | undefined
    if (contentRef?.current && containerRef?.current) {
      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries.length < 2) {
          return
        }

        const themeLineHeight = theme.typography.bodyText.largeDevice.lineHeight.toString()
        const lineHeight = parseInt(themeLineHeight.replace('px', '') || '', 10)
        const maxClosedContainerHeight =
          lineHeight * (props.numberOfLinesToShow || READ_MORE_LINE_PREVIEW)

        const { height: contentWrapperHeight } = entries[0].contentRect
        const { height: contentHeight } = entries[1].contentRect

        const containerHeight =
          contentWrapperHeight <= maxClosedContainerHeight
            ? contentWrapperHeight
            : maxClosedContainerHeight

        contentMaxHeight.current = contentHeight
        setShowReadMore(contentHeight > containerHeight)
      })
      resizeObserver.observe(containerRef.current)
      resizeObserver.observe(contentRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <ReadMoreContainer open={open}>
      <ReadMoreContentContainer
        ref={containerRef}
        maxHeight={contentMaxHeight.current}
        open={open}
        numberOfLinesToShow={props.numberOfLinesToShow}
      >
        <Content
          ref={contentRef}
          content={props.content}
          open={open}
          horizontalAlignment={props.contentHorizontalAlignment}
        />
      </ReadMoreContentContainer>
      {showReadMore && (
        <ButtonIconContainer
          data-testid="read-more-button"
          horizontalAlignment={props.horizontalAlignment}
          open={open}
        >
          <StyledButton
            emphasis="low"
            aria-expanded={open}
            onClick={() => setOpen(!open)}
          >
            {open ? props.readLessText : props.readMoreText}
          </StyledButton>
          {theme.patterns.readMore.chevronIcon.svgPath ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.patterns.readMore.chevronIcon.viewBox}
              width={theme.patterns.readMore.chevronIcon.width}
              height={theme.patterns.readMore.chevronIcon.height}
              aria-hidden={true}
            >
              <path
                d={theme.patterns.readMore.chevronIcon.svgPath}
                fillRule="evenodd"
              />
            </SvgIcon>
          ) : (
            <ChevronDown aria-hidden={true} />
          )}
        </ButtonIconContainer>
      )}
    </ReadMoreContainer>
  )
}
