import {
  FacetInput,
  FacetSelectionInput,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { facetsCallbacks } from '../dataTracking'
import { FacetDataLayerSchema } from './types'

/**
 * Gets the string to be used as the URL parameter for facets.
 * @param facetList - The facets to be used in the URL parameter.
 * @returns The string to be used as the URL parameter for facets.
 */
export const getFacetUrlFromInput = (facetList: FacetInput[]): string => {
  if (facetList.length === 0) {
    return ''
  }

  return facetList
    .filter((facet) => facet.selections.length > 0)
    .map((facet) => getFacetSelectionString(facet.facetName, facet.selections))
    .join('|')
}

const getFacetSelectionString = (
  facetName: string,
  selections: FacetSelectionInput[],
): string => {
  return selections
    .map((selection) => {
      const selectionString = selection.optionName
        ? selection.optionName
        : `[${selectionNumToString(selection.from)} TO ${selectionNumToString(
            selection.to,
          )}]`

      return `${facetName}:${selectionString}`
    })
    .join('|')
}

const getSelectedFacet = (
  selection: FacetSelectionInput,
  facetName: string,
  facetHeader: string,
) => {
  if (!selection) {
    return ''
  }

  if (selection.optionName) {
    return `${facetHeader}:${facetName}:${selection.optionName}`
  }

  if (
    (selection.from || selection.from === 0) &&
    (selection.to || selection.to === 0)
  ) {
    return `${facetHeader}:${facetName}:${selection.from} to ${selection.to}`
  }

  return ''
}

const selectionNumToString = (selectionNum?: number | null) => {
  return selectionNum === null || selectionNum === undefined
    ? '*'
    : selectionNum.toString()
}
/**
 * A function that merges a new facet selection with an existing facet selection.
 * @param facetName - The name of the facet.
 * @param selection - A FacetSelection object to be added to current selections.
 * @param currentSelectedFacets - The current selected facets.
 * @param useGA4EnhancedEcom - site config to render the new GA4
 * @param facetSchema - schema for the GA event parameters
 * @returns An object containing the updated selected facets.
 */
export const addFacet = (
  facetName: string,
  facetHeader: string,
  selection: FacetSelectionInput,
  currentSelectedFacets: FacetInput[],
  useGA4EnhancedEcom?: boolean,
  facetSchema?: FacetDataLayerSchema,
) => {
  const newSelectedFacets = [...currentSelectedFacets]
  const existingIndex = newSelectedFacets.findIndex(
    (facet) => facet.facetName === facetName,
  )

  existingIndex !== -1
    ? newSelectedFacets[existingIndex].selections.push(selection)
    : newSelectedFacets.push({
        facetName,
        selections: [selection],
      })

  const selectedFacet = getSelectedFacet(selection, facetName, facetHeader)

  facetsCallbacks.addFacetClicked(selectedFacet)
  useGA4EnhancedEcom &&
    facetSchema &&
    facetsCallbacks.addFacetToDataLayer(selectedFacet, facetSchema)

  return newSelectedFacets
}
/**
 * A function that removes facet selection from an existing facet selection.
 * @param facetName - The name of the facet.
 * @param selection - A FacetSelection object to be added to be removed.
 * @param currentSelectedFacets - The current selected facets.
 * @returns An object containing the updated selected facets.
 */
export const removeFacet = (
  facetName: string,
  selection: FacetSelectionInput,
  currentSelectedFacets: FacetInput[],
) => {
  const newSelectedFacets = [...currentSelectedFacets]
  const existingIndex = newSelectedFacets.findIndex(
    (facet) => facet.facetName === facetName,
  )
  if (existingIndex === -1) return currentSelectedFacets

  if (newSelectedFacets[existingIndex]?.selections?.length > 1) {
    newSelectedFacets[existingIndex].selections = newSelectedFacets[
      existingIndex
    ].selections.filter((currentSelection) =>
      selection.optionName
        ? currentSelection.optionName !== selection.optionName
        : currentSelection.from !== selection.from &&
          currentSelection.to !== selection.to,
    )
  } else {
    newSelectedFacets.splice(existingIndex, 1)
  }

  const removedFacet = selection?.optionName
    ? `${facetName}:${selection.optionName}`
    : ''
  facetsCallbacks.removeFacetClicked(removedFacet)

  return newSelectedFacets
}

export const clearFacetSelection = (
  selection: string,
  selectedFacets: FacetInput[],
): FacetInput[] => {
  const facetIndex = selectedFacets.findIndex(
    (facet) => facet.facetName === selection,
  )

  if (facetIndex > -1) {
    const newFacets = [...selectedFacets]
    newFacets.splice(facetIndex, 1)
    return newFacets
  }
  return selectedFacets
}

export const isSelectedFacet = (
  facetName: string,
  selection: FacetSelectionInput,
  selectedFacets: FacetInput[],
): boolean => {
  return !!selectedFacets
    .find((selectedFacet) => selectedFacet.facetName === facetName)
    ?.selections.find(
      (selectedOption) =>
        selectedOption.optionName === selection.optionName &&
        selectedOption.from === selection.from &&
        selectedOption.to === selection.to,
    )
}

export enum UpdateFacetAction {
  AddFacet,
  RemoveFacet,
}

export const updateFacetSelection = (
  updateFacetAction: UpdateFacetAction,
  facetName: string,
  facetHeader: string,
  selection: FacetSelectionInput,
  selectedFacets: FacetInput[],
  useGA4EnhancedEcom?: boolean,
  facetSchema?: FacetDataLayerSchema,
): FacetInput[] => {
  const newSelectedFacets =
    updateFacetAction === UpdateFacetAction.AddFacet
      ? addFacet(
          facetName,
          facetHeader,
          selection,
          selectedFacets,
          useGA4EnhancedEcom,
          facetSchema,
        )
      : removeFacet(facetName, selection, selectedFacets)

  return newSelectedFacets
}

export const displayFacet = (
  facetOptions: { matchedProductCount: number }[],
) => {
  return facetOptions.findIndex((option) => option.matchedProductCount > 0) > -1
}

// Needed to prevent it from just adding the new facet to the URL
export const updateRangedFacets = (
  facetName: string,
  facetHeader: string,
  selection: FacetSelectionInput,
  selectedFacets: FacetInput[],
  useGA4EnhancedEcom?: boolean,
  facetSchema?: FacetDataLayerSchema,
) => {
  const isSelectedAlready = selectedFacets.some(
    (facet) => facet.facetName === facetName,
  )
  let newFacets = selectedFacets
  if (isSelectedAlready) {
    newFacets = removeFacet(
      facetName,
      selectedFacets.find((facet) => facet.facetName === facetName)!
        .selections[0],
      selectedFacets,
    )
  }
  newFacets = addFacet(
    facetName,
    facetHeader,
    selection,
    newFacets,
    useGA4EnhancedEcom,
    facetSchema,
  )
  return newFacets
}
