import * as React from 'react'
import handleViewport from 'react-in-viewport'
import dynamic from 'next/dynamic'

const SearchIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/SearchIcon'),
)
import { FacetSelectionInput } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { css, mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import { Checkbox, Input } from '@thg-commerce/gravity-elements'

import { PriceSliderFacet } from '../FacetComponents'
import { Facet, FacetDataLayerSchema, FacetProps } from '../types'
import {
  isSelectedFacet,
  UpdateFacetAction,
  updateFacetSelection,
  updateRangedFacets,
} from '../utils'

type FacetOption = FacetSelectionInput & {
  displayName: string
  matchedProductCount: number
}

export interface FacetContentProps extends FacetProps {
  maxHeight?: string
  isMobile?: boolean
  brandSearch?: {
    state: string
    setState: (searchString: string) => void
  }
  i18nText: {
    searchBrandLabel: string
    searchBrandPlaceholder: string
    minimumLabel: string
    maximumLabel: string
    sliderHeader: string
  }
  currency?: string
  isHorizontalFacet?: boolean
  useGA4EnhancedEcom?: boolean
  facetSchema: FacetDataLayerSchema
}

export const BrandInputWrapper = styled.div<{
  isMobile?: boolean
}>`
  margin-top: ${spacing(2)};

  ${(props) =>
    props.isMobile &&
    `
      margin-top: ${spacing(4)};

      ${mq(props.theme.breakpointUtils.map, 'sm')} {
        margin-top: ${spacing(5)};
       }
       
       @media (orientation: landscape) {
          margin-top: ${spacing(2)};
        }
    `}
`

const containerStyling = css<{
  maxHeight?: string
  isMobile?: boolean
}>`
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight}`};
  overflow-y: auto;

  ${(props) =>
    props.isMobile
      ? `
        margin: ${spacing(4)} 0;

        ${mq(props.theme.breakpointUtils.map, 'sm')} {
          margin: ${spacing(6)} 0;
        }
        
        @media (orientation: landscape) {
           margin: 0;
          }
      `
      : `
        margin: ${spacing(1)} ${spacing(1)} 0 0;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: ${props.theme.colors.palette.greys.lighter};
        }

        &::-webkit-scrollbar-thumb {
          background: ${props.theme.colors.palette.greys.grey};
        }
      `}
`

const itemStyling = css<{
  isMobile?: boolean
  hasBorder?: boolean
}>`
  margin: 0;

  ${(props) =>
    props.hasBorder &&
    `border: 1px solid ${props.theme.colors.palette.greys.grey};
    margin: 0 ${spacing(2)} ${spacing(2)} ${spacing(2)}

    &:hover {
      border: 1px solid ${props.theme.colors.palette.greys.darker}
    }`};

  ${(props) =>
    props.isMobile &&
    `
      border-bottom: 1px solid ${props.theme.colors.palette.greys.light};
      padding-bottom: ${spacing(1)};

      &:not(:first-child) {
        padding-top: ${spacing(1)};
      }
    `}
  }
`

const StyledDivContainer = styled.div<{
  maxHeight?: string
  isMobile?: boolean
}>`
  ${containerStyling}
`

export const StyledListContainer = styled.ul<{
  maxHeight?: string
  isMobile?: boolean
}>`
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight}`};
  overflow-y: auto;
  margin: 0;

  ${(props) =>
    props.isMobile
      ? `
          margin: ${spacing(4)} 0;

          ${mq(props.theme.breakpointUtils.map, 'sm')} {
            margin: ${spacing(6)} 0;
          }
          
           @media (orientation: landscape) {
           margin: ${spacing(3)} 0;
          }
        `
      : `
          margin: ${spacing(1)} 0 0 0;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background: ${props.theme.colors.palette.greys.lighter};
          }

          &::-webkit-scrollbar-thumb {
            background: ${props.theme.colors.palette.greys.grey};
          }
        `}
`

export const StyledListItem = styled.li<{
  isMobile?: boolean
  hasBorder?: boolean
}>`
  margin: 0;

  ${(props) =>
    props.hasBorder &&
    `
    border: 1px solid transparent;
    border-bottom: 1px solid ${props.theme.colors.palette.greys.light};
    margin: 0 ${spacing(2)} ${spacing(2)} ${spacing(2)}

    &:hover {
      background-color: ${props.theme.colors.palette.greys.lighter}
    }`};

  ${(props) =>
    props.isMobile &&
    `
      border-bottom: 1px solid ${props.theme.colors.palette.greys.light};
      padding-bottom: ${spacing(1)};

      &:not(:first-child) {
        padding-top: ${spacing(1)};
      }
    `}
`

export const StyledDivItem = styled.div<{
  isMobile?: boolean
  hasBorder?: boolean
}>`
  ${itemStyling}
`

const FacetOptionItemInView = ({
  inViewport,
  enterCount,
  contentProps,
  option,
  getStateHandler,
  isSelected,
  forwardedRef,
}: FaceOptionItem & {
  inViewport: boolean
  enterCount: number
  forwardedRef: React.Ref<any>
}) => {
  return inViewport || enterCount > 0 ? (
    <FacetOptionItem
      contentProps={contentProps}
      option={option}
      getStateHandler={getStateHandler}
      isSelected={isSelected}
      forwardedRef={forwardedRef}
    />
  ) : (
    <li ref={forwardedRef} style={{ height: '48px', width: '100%' }}></li>
  )
}

interface FaceOptionItem {
  contentProps: FacetContentProps
  option: FacetOption
  getStateHandler: (state: boolean) => void
  isSelected: boolean
  forwardedRef?: React.Ref<any>
}

const FacetOptionItem = ({
  contentProps,
  option,
  isSelected,
  getStateHandler,
  forwardedRef,
}: FaceOptionItem) => {
  return (
    <StyledListItem
      data-testid={`facet-option-${option.displayName}`}
      key={`facetcontent-${option.displayName}`}
      isMobile={contentProps.isMobile}
      hasBorder={contentProps.isHorizontalFacet}
      ref={forwardedRef}
    >
      <Checkbox
        data-testid={`facet-option-checkbox-${option.displayName}`}
        thinBorder
        transparentBorder
        width="100%"
        thinFocusBorder={contentProps.isHorizontalFacet}
        checked={isSelected}
        label={`${option.displayName} (${option.matchedProductCount})`}
        getState={getStateHandler}
      />
    </StyledListItem>
  )
}

export const FacetContent = React.memo((props: FacetContentProps) => {
  const [brandSearch, setBrandSearch] = React.useState('')
  const brandSearchState = props.brandSearch?.state ?? brandSearch
  const brandSearchSetState = props.brandSearch?.setState ?? setBrandSearch

  const FacetOptionItemInViewComponent = handleViewport<FaceOptionItem>(
    FacetOptionItemInView,
  )

  const getFacetOptionsList = React.useMemo(
    () => (facetOptions: FacetOption[]) => {
      return (
        <StyledListContainer
          data-testid={`facet-container-${props.facet.facetName}`}
          maxHeight={props.maxHeight}
          isMobile={props.isMobile}
        >
          {facetOptions
            .filter((option) => option.matchedProductCount > 0)
            .map((option, index) => {
              const isSelected = isSelectedFacet(
                props.facet.facetName,
                option,
                props.selectedFacets,
              )

              return (props.isMobile ? index >= 10 : index >= 5) ? (
                <FacetOptionItemInViewComponent
                  contentProps={props}
                  option={option}
                  getStateHandler={(state) => {
                    if (state !== isSelected) {
                      const newFacets = updateFacetSelection(
                        state
                          ? UpdateFacetAction.AddFacet
                          : UpdateFacetAction.RemoveFacet,
                        props.facet.facetName,
                        props.facet.facetHeader,
                        option.optionName
                          ? {
                              optionName: option.optionName,
                            }
                          : {
                              from: option.from,
                              to: option.to,
                            },
                        props.selectedFacets,
                        props.useGA4EnhancedEcom,
                        props.facetSchema,
                      )
                      props.onInputChange({
                        facets: newFacets,
                      })
                    }
                  }}
                  isSelected={isSelected}
                />
              ) : (
                <FacetOptionItem
                  contentProps={props}
                  option={option}
                  getStateHandler={(state) => {
                    if (state !== isSelected) {
                      const newFacets = updateFacetSelection(
                        state
                          ? UpdateFacetAction.AddFacet
                          : UpdateFacetAction.RemoveFacet,
                        props.facet.facetName,
                        props.facet.facetHeader,
                        option.optionName
                          ? {
                              optionName: option.optionName,
                            }
                          : {
                              from: option.from,
                              to: option.to,
                            },
                        props.selectedFacets,
                        props.useGA4EnhancedEcom,
                        props.facetSchema,
                      )
                      props.onInputChange({
                        facets: newFacets,
                      })
                    }
                  }}
                  isSelected={isSelected}
                />
              )
            })}
        </StyledListContainer>
      )
    },
    [brandSearch, props.selectedFacets],
  )

  const isBrandContent = props.facet.facetName.includes('brand_content')

  if (props.facet.__typename === Facet.RANGED) {
    return getFacetOptionsList(props.facet.options)
  }
  if (props.facet.__typename === Facet.SLIDER) {
    const selectedValues = props.selectedFacets.filter(
      (facet) => facet.facetName === props.facet.facetName,
    )
    return (
      <StyledDivContainer
        data-testid={`facet-slider-container-${props.facet.facetName}`}
        isMobile={props.isMobile}
        maxHeight={props.maxHeight}
      >
        <StyledDivItem>
          <PriceSliderFacet
            maximumPrice={props.facet.maxValue}
            currentMinimumPrice={
              selectedValues.length > 0
                ? (selectedValues[0].selections[0].from as number)
                : 0
            }
            currentMaximumPrice={
              selectedValues.length > 0
                ? (selectedValues[0].selections[0].to as number)
                : props.facet.maxValue
            }
            onChange={({ minimumPrice, maximumPrice }) => {
              const newFacets = updateRangedFacets(
                props.facet.facetName,
                props.facet.facetHeader,
                {
                  from: minimumPrice,
                  to: maximumPrice,
                },
                props.selectedFacets,
                props.useGA4EnhancedEcom,
                props.facetSchema,
              )
              props.onInputChange({
                facets: newFacets,
              })
            }}
            i18nText={props.i18nText}
          />
        </StyledDivItem>
      </StyledDivContainer>
    )
  }

  const filteredOptions = React.useMemo(
    () =>
      props.facet.options?.filter((option) =>
        isBrandContent
          ? option.optionName
              .toLowerCase()
              .includes(brandSearchState.toLowerCase())
          : true,
      ),
    [props.facet.options, isBrandContent, brandSearchState],
  )

  if (props.facet.__typename === Facet.SIMPLE) {
    return (
      <React.Fragment>
        {isBrandContent && (
          <BrandInputWrapper
            data-testid={`facet-brand-input-container-${props.facet.facetName}`}
            isMobile={props.isMobile}
          >
            <Input
              data-testid={`facet-brand-input-${props.facet.facetName}`}
              label={props.i18nText.searchBrandLabel}
              labelHidden={true}
              placeholder={props.i18nText.searchBrandPlaceholder}
              svgIcon={<SearchIcon />}
              onChange={(event) => brandSearchSetState(event.target.value)}
              clearInputValueHandler={() => brandSearchSetState('')}
              valueOverride={brandSearchState}
              svgIconLeftAlign={false}
            />
          </BrandInputWrapper>
        )}
        {getFacetOptionsList(filteredOptions)}
      </React.Fragment>
    )
  }
  return null
})
