import * as React from 'react'
import handleViewport from 'react-in-viewport'

import {
  CallbackSetEvent,
  PlacementContentType,
  QubitViewType,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import { useLogger, useSiteConfig } from '@thg-commerce/enterprise-core'
import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'

import { useQubit } from '../useQubit'
import {
  sendImpression,
  sendPlacementImpressionEvents,
} from '../qubitImpressions'
import { PersonalisedContent } from './PersonalisedContent'

interface QubitPersonalisedContentType {
  placementId: string
  emitImpressionEvent?: (params: {
    logger: any
    eventType: CallbackSetEvent
    callbackData?: string
    callbackURL?: string
  }) => void
}

const PersonalisedContentViewport = handleViewport(
  PersonalisedContent,
  {},
  { disconnectOnLeave: true },
)
export const QubitPersonalisedContent = (
  props: QubitPersonalisedContentType,
) => {
  const logger = useLogger()
  const { qubit } = useSiteConfig()
  const impressionEmittedRef = React.useRef(false)

  const data = useQubit({
    mode: qubit?.mode || QubitMode.LIVE,
    type: QubitViewType.CATEGORY,
    placementId: props.placementId,
  })

  if (!data?.qubitPlacements) {
    return null
  }

  const content =
    data.qubitPlacements.content &&
    data.qubitPlacements.content[PlacementContentType.PERSONALISED_CONTENT]

  if (!content) {
    return null
  }

  const onEnterViewport = () => {
    if (impressionEmittedRef.current) {
      return
    }

    sendPlacementImpressionEvents({
      logger,
      sendPlacementLevelImpression: props.emitImpressionEvent,
      callbackData: data.qubitPlacements?.callbackData,
      callbackURL: qubit?.callbackURL,
    })
    impressionEmittedRef.current = true
  }

  return (
    <PersonalisedContentViewport
      content={content}
      onEnterViewport={onEnterViewport}
      onClick={() =>
        sendImpression({
          logger,
          eventType: CallbackSetEvent.CLICK_THROUGH,
          callbackData: data.qubitPlacements?.callbackData,
          callbackURL: qubit?.callbackURL,
        })
      }
    />
  )
}
