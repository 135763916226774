import { useRef } from 'react'

import { CallbackSetEvent } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'

import { sendImpression } from './qubitImpressions'

export const useQubitPlacementImpression = () => {
  const hasEmittedEventRef = useRef<boolean>(false)

  const emitImpressionEvent = ({
    logger,
    eventType,
    callbackData,
    callbackURL,
  }: {
    logger: any
    eventType: CallbackSetEvent
    callbackData?: string
    callbackURL?: string
  }) => {
    if (hasEmittedEventRef.current || !callbackData || !callbackURL) {
      return
    }

    sendImpression({
      logger,
      callbackData,
      callbackURL,
      eventType,
    })
    hasEmittedEventRef.current = true
  }

  return emitImpressionEvent
}
