import * as React from 'react'

import { i18n } from '@thg-commerce/enterprise-core'
import {
  SimpleFacet,
  SimpleFacetOption,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Checkbox } from '@thg-commerce/gravity-elements'
import SearchIcon from '@thg-commerce/gravity-icons/src/components/SearchIcon'
import { KeyboardKeys } from '@thg-commerce/gravity-theme'

import { BaseFacetProps, FacetDataLayerSchema } from '../../types'
import {
  isSelectedFacet,
  UpdateFacetAction,
  updateFacetSelection,
} from '../../utils'

import {
  AlphabetHeaderButton,
  AlphabetHeaderList,
  AlphabetHeaderListItem,
  BrandSearch,
  BrandSearchContainer,
  FacetBorderListItem,
  FacetCategory,
  FacetSelectionPanel,
  FacetSelectionWrapper,
  HidableElement,
} from './styles'

export interface BrandFacetProps extends BaseFacetProps {
  facet: SimpleFacet
  hasBrandSearch?: boolean
  useGA4EnhancedEcom?: boolean
  facetSchema: FacetDataLayerSchema
}

type AlphabetCategory = {
  category: string
  options: SimpleFacetOption[]
}

const ENGLISH_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const NUMBER_CATEGORY = '0-9'

const initAlphabetCategoryList = (): AlphabetCategory[] =>
  ENGLISH_ALPHABET.split('')
    .map((letter) => {
      return { category: letter, options: [] }
    })
    .concat({ category: NUMBER_CATEGORY, options: [] })

const populateAlphabetCategoryList = (
  optionList: AlphabetCategory[],
  facet: SimpleFacet,
): AlphabetCategory[] => {
  facet.options.forEach((facet) => {
    const facetCategory = facet.optionName.match(/^\d+/)
      ? NUMBER_CATEGORY
      : facet.optionName.charAt(0).toUpperCase()

    const categoryFound = optionList
      .find((element) => element.category === facetCategory)
      ?.options.push(facet)

    if (!categoryFound) {
      optionList.splice(optionList.length - 1, 0, {
        category: facetCategory,
        options: [facet],
      })
    }
  })
  return optionList
}

const sortCategories = (
  facetList: AlphabetCategory[],
  ignoreKey: string[],
): AlphabetCategory[] =>
  facetList.sort(
    (element1, element2) =>
      (!ignoreKey.includes(element1.category) &&
        element1.category.localeCompare(element2.category)) ||
      0,
  )

const sortOptions = (optionsList: AlphabetCategory[]): AlphabetCategory[] =>
  optionsList.map((category) => {
    return {
      ...category,
      options: category.options.sort((option, option2) =>
        option.optionName.localeCompare(option2.optionName),
      ),
    }
  })

export const BrandFacet = (props: BrandFacetProps) => {
  const allI18nText = i18n('product.list.facets.horizontal.brand.all')

  const [currentCategory, setCurrentCategory] = React.useState<string>(
    allI18nText,
  )
  const [searchValue, setSearchValue] = React.useState<string>('')

  const handleInputOnChange = (value) => {
    setSearchValue(value)
  }

  const handleClearInput = () => {
    setSearchValue('')
  }

  if (
    !props.facet?.facetName?.includes('brand_content') ||
    props.facet.options.length < 1
  ) {
    return null
  }

  const handleOnAlphabetHeaderButtonKeyDown = (letter: string) => {
    letter === currentCategory
      ? setCurrentCategory(allI18nText)
      : setCurrentCategory(letter)
  }

  const createCategoryList = React.useMemo(() => {
    const initialAlphabetCategoryList = initAlphabetCategoryList()
    const populatedAlphabetCategoryList = populateAlphabetCategoryList(
      initialAlphabetCategoryList,
      props.facet,
    )
    const sortedCategories = sortCategories(populatedAlphabetCategoryList, [
      NUMBER_CATEGORY,
      allI18nText,
    ])

    return sortOptions(sortedCategories)
  }, [searchValue])

  const categoryList = createCategoryList
  const firstSearchChar = searchValue.charAt(0).toLowerCase()
  const activeBrandSearch = categoryList.filter((element) =>
    element.options.find(
      (option) =>
        option.displayName.charAt(0).toLowerCase() === firstSearchChar,
    ),
  )
  const facetOptions = (searchValue !== ''
    ? activeBrandSearch
    : categoryList
  ).map((element) => (
    <HidableElement
      key={element.category}
      hide={
        currentCategory !== allI18nText && currentCategory !== element.category
      }
    >
      {element.options.length > 0 && (
        <React.Fragment>
          <FacetCategory>{element.category}</FacetCategory>
          <FacetSelectionWrapper>
            {element.options
              .filter((option) =>
                option.displayName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()),
              )
              .map((item) => {
                const isSelected = isSelectedFacet(
                  props.facet.facetName,
                  { optionName: item.optionName },
                  props.selectedFacets,
                )

                return (
                  <FacetBorderListItem hasBorder key={item.optionName}>
                    <Checkbox
                      thinBorder
                      transparentBorder
                      width="100%"
                      thinFocusBorder
                      label={`${item.displayName} (${item.matchedProductCount})`}
                      checked={isSelected}
                      getState={(isChecked) => {
                        if (isChecked !== isSelected) {
                          const newFacets = updateFacetSelection(
                            isChecked
                              ? UpdateFacetAction.AddFacet
                              : UpdateFacetAction.RemoveFacet,
                            props.facet.facetName,
                            props.facet.facetHeader,
                            { optionName: item.optionName },
                            props.selectedFacets,
                            props.useGA4EnhancedEcom,
                            props.facetSchema,
                          )
                          props.onInputChange({
                            facets: newFacets,
                          })
                        }
                      }}
                    />
                  </FacetBorderListItem>
                )
              })}
          </FacetSelectionWrapper>
        </React.Fragment>
      )}
    </HidableElement>
  ))

  return (
    <React.Fragment>
      {props.hasBrandSearch && (
        <BrandSearchContainer data-testid="brand-search-container">
          <BrandSearch
            valueOverride={searchValue}
            label="Search"
            labelHidden
            placeholder="Brand Search"
            autocomplete="off"
            delete
            svgIcon={<SearchIcon />}
            svgIconLeftAlign={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputOnChange(e.target.value)
            }}
            clearInputValueHandler={handleClearInput}
          />
        </BrandSearchContainer>
      )}
      <AlphabetHeaderList>
        <AlphabetHeaderListItem
          active={currentCategory === allI18nText}
          onClick={() => setCurrentCategory(allI18nText)}
          key="all"
        >
          <AlphabetHeaderButton
            data-testid={`alphabet-button-all-${
              currentCategory === allI18nText ? 'active' : 'inactive'
            }`}
            active={currentCategory === allI18nText}
            role="checkbox"
            aria-checked={currentCategory === allI18nText}
          >
            {allI18nText}
          </AlphabetHeaderButton>
        </AlphabetHeaderListItem>
        {categoryList.map((element) => (
          <AlphabetHeaderListItem
            active={currentCategory === element.category}
            disabled={!(element.options.length > 0)}
            key={element.category}
            onClick={() =>
              element.options.length > 0 &&
              handleOnAlphabetHeaderButtonKeyDown(element.category)
            }
          >
            <AlphabetHeaderButton
              disabled={!(element.options.length > 0)}
              data-testid={`alphabet-button-${element.category}-${
                currentCategory === element.category ? 'active' : 'inactive'
              }`}
              active={currentCategory === element.category}
              role="checkbox"
              aria-checked={currentCategory === element.category}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === KeyboardKeys.Spacebar) {
                  event.preventDefault()
                  handleOnAlphabetHeaderButtonKeyDown(element.category)
                }
              }}
            >
              {element.category}
            </AlphabetHeaderButton>
          </AlphabetHeaderListItem>
        ))}
      </AlphabetHeaderList>
      <FacetSelectionPanel>{facetOptions}</FacetSelectionPanel>
    </React.Fragment>
  )
}
