import {
  opacity,
  spacing,
  styled,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/enterprise-theme'
import { gradient } from '@thg-commerce/gravity-theme'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'
import { CarouselButtonStyle } from './HorizontalScrollWrapper'

export const Container = styled.div<{ alignment: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${(props) =>
    `justify-content: ${
      props.alignment === HorizontalAlignment.CENTER ? 'center' : 'flex-start'
    };`}
`

export const ListContainer = styled.ul<{ gap?: number }>`
  align-items: center;
  position: relative;
  display: flex;
  list-style: none;
  overflow-x: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: auto;
  height: 100%;
  ${(props) => props.gap && `gap: ${spacing(props.gap)};`};

  &::-webkit-scrollbar {
    display: none;
  }
`
export const CarouselButton = styled.div<{
  displayOpacity: number
  backgroundColour: string
  buttonStyle: CarouselButtonStyle
  hide: boolean
}>`
  position: absolute;
  display: flex;
  top: 0;
  ${zIndex(ZIndexLevel.Base)};
  width: 40px;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  opacity: ${(props) => (props.displayOpacity <= 0 ? '0' : '1')};
`

export const LeftCarouselButton = styled(CarouselButton)`
  left: 0;
  background: ${(props) =>
    props.buttonStyle === CarouselButtonStyle.OPACITY
      ? opacity(props.backgroundColour, 0.9)
      : gradient('270deg', [
          {
            color: opacity(props.backgroundColour, 0),
            stop: 0,
          },
          {
            color: opacity(props.backgroundColour, 0.8),
            stop: 28.13,
          },
          {
            color: opacity(props.backgroundColour, 1),
            stop: 100,
          },
        ])};
`

export const RightCarouselButton = styled(CarouselButton)`
  right: 0;
  background: ${(props) =>
    props.buttonStyle === CarouselButtonStyle.OPACITY
      ? opacity(props.backgroundColour, 0.9)
      : gradient('90deg', [
          {
            color: opacity(props.backgroundColour, 0),
            stop: 0,
          },
          {
            color: opacity(props.backgroundColour, 0.8),
            stop: 28.13,
          },
          {
            color: opacity(props.backgroundColour, 1),
            stop: 100,
          },
        ])};
`
