import { SponsoredAdsPLP as SPONSORED_ADS_PLP_QUERY } from '@thg-commerce/enterprise-components/SponsoredAds/graphql/SponsoredAdsPLP.graphql'
import { SponsoredAdsSearch as SPONSORED_ADS_SEARCH_QUERY } from '@thg-commerce/enterprise-components/SponsoredAds/graphql/SponsoredAdsSearch.graphql'
import { SponsoredAdsPDP as SPONSORED_ADS_PDP_QUERY } from '@thg-commerce/enterprise-components/SponsoredAds/graphql/SponsoredAdsPDP.graphql'
import { ESIRequestContext } from '@thg-commerce/enterprise-esi/src/types'
import { ComponentName } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/ComponentWidgets'
import {
  Country,
  Currency,
  FacetInput,
  Feature,
  ProductSort,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { HmacAppliedPlacementWithBeacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'

import { SponsoredAdsInitialProps, SponsoredAdsProps } from './SponsoredAds'

export enum SponsoredAdsType {
  Search = 'search',
  PLP = 'plp',
  PDP = 'pdp',
}

export interface Query {
  options: {
    queryString
    queryVariables
  }
}

type SponsoredAdsReturnTypeKeys =
  | 'sponsoredAdsPLP'
  | 'sponsoredAdsPDP'
  | 'sponsoredAdsSearch'

export type SponsoredAdsQueryReturnType = {
  [K in SponsoredAdsReturnTypeKeys]?: {
    sponsoredAds: HmacAppliedPlacementWithBeacon[] | null
  }
}

export type QueryBuilderFunction = (context) => Query

export type QueryMap = {
  [key in SponsoredAdsType]: QueryBuilderFunction
}

export interface SearchData {
  itemsPerPage: number
  searchQuery: string
  sortOrder: ProductSort
  facets: FacetInput[]
  pageNumber: number
}

const buildSearchQuery = (
  context: ESIRequestContext<SponsoredAdsInitialProps & SponsoredAdsProps>,
): Query => {
  const { publicRuntimeConfig } = context.config
  const { siteConfig, siteDefinition } = publicRuntimeConfig

  const { horizonFeatures } = context.req

  const {
    enableWishlists,
    enableWishlistsGlobal,
    hideProductListWishlists,
  } = siteConfig

  const wishlistEnabled =
    (enableWishlists &&
      enableWishlistsGlobal &&
      !hideProductListWishlists &&
      horizonFeatures?.includes(Feature.Wishlist)) ||
    false
  const clickAndCollectEnabled = horizonFeatures?.includes(
    Feature.ClickAndCollect,
  )

  const queryOptions = context.props.searchData
    ? {
        searchQuery: context.props.searchData.searchQuery,
        facets: context.props.searchData.facets,
        itemsPerPage: context.props.searchData.itemsPerPage,
        pageNumber: context.props.searchData.pageNumber,
        sortOrder: context.props.searchData.sortOrder,
      }
    : {
        searchQuery: '',
        facets: [],
        itemsPerPage: 0,
        pageNumber: 1,
        sortOrder: ProductSort.Relevance,
      }

  const shippingDestination = (context.req.config.sessionSettings
    ?.shippingDestination ||
    siteDefinition.defaultSessionSettings.shippingDestination) as Country
  const currency = (context.req.config.sessionSettings?.currency ||
    siteDefinition.defaultSessionSettings.currency) as Currency

  return {
    options: {
      queryString: SPONSORED_ADS_SEARCH_QUERY,
      queryVariables: {
        wishlistEnabled,
        query: queryOptions.searchQuery,
        name: ComponentName.NORESULTS,
        currency: currency as Currency,
        shippingDestination: shippingDestination as Country,
        clickAndCollectEnabled: clickAndCollectEnabled ?? false,
        facets: queryOptions.facets,
        limit: queryOptions.itemsPerPage,
        offset:
          queryOptions.pageNumber > 1
            ? queryOptions.itemsPerPage * (queryOptions.pageNumber - 1)
            : 0,
        sort: queryOptions.sortOrder,
      },
    },
  }
}

const buildPDPQuery = (
  context: ESIRequestContext<SponsoredAdsInitialProps & SponsoredAdsProps>,
): Query => {
  const { publicRuntimeConfig } = context.config
  const { siteDefinition } = publicRuntimeConfig

  const shippingDestination = (context.req.config.sessionSettings
    ?.shippingDestination ||
    siteDefinition.defaultSessionSettings.shippingDestination) as Country
  const currency = (context.req.config.sessionSettings?.currency ||
    siteDefinition.defaultSessionSettings.currency) as Currency

  return {
    options: {
      queryString: SPONSORED_ADS_PDP_QUERY,
      queryVariables: {
        shippingDestination,
        currency,
        sku: context.props.productSku,
        strict: false,
      },
    },
  }
}

const buildPLPQuery = (
  context: ESIRequestContext<SponsoredAdsInitialProps & SponsoredAdsProps>,
): Query => {
  const { publicRuntimeConfig } = context.config
  const { siteDefinition } = publicRuntimeConfig

  const shippingDestination = (context.req.config.sessionSettings
    ?.shippingDestination ||
    siteDefinition.defaultSessionSettings.shippingDestination) as Country
  const currency = (context.req.config.sessionSettings?.currency ||
    siteDefinition.defaultSessionSettings.currency) as Currency
  const path = context.props.path ?? ''

  return {
    options: {
      queryString: SPONSORED_ADS_PLP_QUERY,
      queryVariables: {
        path,
        currency,
        shippingDestination,
        input: {
          currency,
          shippingDestination,
          facets: [],
        },
      },
    },
  }
}

export const sponsoredAdsQueryTypes: QueryMap = {
  [SponsoredAdsType.Search]: buildSearchQuery,
  [SponsoredAdsType.PLP]: buildPLPQuery,
  [SponsoredAdsType.PDP]: buildPDPQuery,
}

export const unwrapSponsoredAdsResponse = (
  data: SponsoredAdsQueryReturnType,
) => {
  const firstKey = Object.keys(data)[0] as SponsoredAdsReturnTypeKeys

  return data[firstKey]?.sponsoredAds?.[0]
}
