import dynamic from 'next/dynamic'
import withHydrationOnDemand from 'react-hydration-on-demand'

import { Input } from '@thg-commerce/gravity-elements'
import { styled, spacing, mq, Text } from '@thg-commerce/gravity-theme'

const SliderInput = dynamic<any>(() =>
  import('@thg-commerce/gravity-elements/SliderInput').then(
    (mod) => mod.SliderInput,
  ),
)

const SliderInputComponent = withHydrationOnDemand({ on: [['visible']] })(
  SliderInput,
)

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${spacing(2)} ${spacing(3)};
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: ${spacing(1)};
`

export const StyledInput = styled(Input)`
  width: 100%;
  height: 100%;
  border: 0;
  margin: auto;
`

export const InputFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.palette.greys.light};
  border-radius: 0.25rem;
  max-width: ${spacing(12.5)};
  min-height: ${spacing(6)};

  label > span {
    margin-bottom: 0;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    min-width: ${spacing(12.5)};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    ${Text('xsmall', 'default')};
    padding: 0;
  }
`

export const PriceSliderHeader = styled.p`
  ${Text('bodyText', 'default')};
  padding: ${spacing(1)} 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${Text('small', 'default')};
  }
`

export const PriceRangeHeader = styled.p`
  ${Text('bodyText', 'alternate')};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${Text('small', 'alternate')};
  }
`

export const StyledPriceSlider = styled(SliderInputComponent)`
  width: 100%;
`
