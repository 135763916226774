import * as React from 'react'
import dynamic from 'next/dynamic'

const CloseIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Close'),
)
import { Button } from '@thg-commerce/gravity-elements'

import { facetsCallbacks } from '../../dataTracking'
import { SelectedFacets } from '../SelectedFacets/SelectedFacets'
import { Facet, FacetDataLayerSchema, FacetsProps } from '../types'
import { displayFacet } from '../utils'

import { MobileFacetSection } from './MobileFacetSection'
import {
  CloseButton,
  Content,
  FacetSectionsContainer,
  Header,
  MobileFacetsContainer,
  SaveButton,
  SaveWrapper,
  SelectedFacetsContainer,
  StyledTitle,
} from './styles'

export interface MobileFacetsProps extends FacetsProps {
  showMobileFacets: boolean
  setShowMobileFacets: (state: boolean) => void
  i18nText: {
    close: string
    clearAll: string
    refine: string
    save: string
    back: string
    clear: string
    searchBrandLabel: string
    searchBrandPlaceholder: string
    minimumLabel: string
    maximumLabel: string
    sliderHeader: string
  }
  setRefreshFacets: (state: boolean) => void
  facetSchema: FacetDataLayerSchema
}

export const MobileFacets = React.memo((props: MobileFacetsProps) => {
  const selectedFacets = props.selectedFacets || []

  const facetsToDisplay = props.facets.map((facet) => {
    return (facet.__typename === Facet.SIMPLE ||
      facet.__typename === Facet.RANGED) &&
      displayFacet(facet.options) ? (
      <MobileFacetSection
        key={`mobilefacet-${facet.facetName}`}
        facet={facet}
        selectedFacets={selectedFacets}
        onInputChange={props.onInputChange}
        setShowMobileFacets={props.setShowMobileFacets}
        setRefreshFacets={props.setRefreshFacets}
        i18nText={{ ...props.i18nText }}
        facetSchema={props.facetSchema}
      />
    ) : (
      facet.__typename === Facet.SLIDER && (
        <MobileFacetSection
          key={`mobilefacet-${facet.facetName}`}
          facet={facet}
          selectedFacets={selectedFacets}
          onInputChange={props.onInputChange}
          setRefreshFacets={props.setRefreshFacets}
          setShowMobileFacets={props.setShowMobileFacets}
          i18nText={{
            ...props.i18nText,
          }}
          facetSchema={props.facetSchema}
        />
      )
    )
  })

  return (
    <MobileFacetsContainer showMobileFacets={props.showMobileFacets}>
      <Header>
        <CloseButton
          data-testid="mobile-facets-close-button"
          onClick={() => {
            props.setShowMobileFacets(false)
            facetsCallbacks.closeMobileFacetsClicked()
          }}
        >
          <CloseIcon />
          {props.i18nText.close}
        </CloseButton>
        {selectedFacets.length > 0 && (
          <Button
            data-testid="mobile-facets-clear-all-button"
            emphasis="low"
            onClick={() => {
              props.onInputChange({
                facets: [],
              })
              props.setRefreshFacets(true)
              facetsCallbacks.clearAllClicked()
            }}
          >
            {props.i18nText.clearAll}
          </Button>
        )}
      </Header>
      <StyledTitle>{props.i18nText.refine}</StyledTitle>
      <Content>
        {selectedFacets.length > 0 && (
          <SelectedFacetsContainer>
            <SelectedFacets
              facets={props.facets}
              selectedFacets={props.selectedFacets}
              onInputChange={(facets) => {
                props.onInputChange(facets)
                props.setRefreshFacets(true)
              }}
              i18nText={{ ...props.i18nText }}
            />
          </SelectedFacetsContainer>
        )}
        <FacetSectionsContainer>{facetsToDisplay}</FacetSectionsContainer>
      </Content>
      <SaveWrapper>
        <SaveButton
          onClick={() => props.setShowMobileFacets(false)}
          data-testid="mobilefacets-save-button"
        >
          {props.i18nText.save}
        </SaveButton>
      </SaveWrapper>
    </MobileFacetsContainer>
  )
})
